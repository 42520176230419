import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Brainshots (2011 - 2014) - Fullstack Developer`}</h1>
    <p>{`In 2011 I joined a group of colleagues (1 year ahead of me) that had just started their own software consultancy business. We worked on building the software components for other emerging startups in the chilean ecosystem. Our prices were low but we also got stocks / participation in the companies we worked with. A risky business model but we were young, inexperienced and with a reputation to build.`}</p>
    <p>{`This was a period where I learned a lot about quickly building solutions and about working collaboratively. Ruby on Rails was the hot thing at the time and we (ab)used its magic to build web apps at lightning speed which allowed us to differentiate ourselves from other more "bureaucratic" consultancies.`}</p>
    <p>{`I didn't work fulltime there, it was mostly a seasonal thing, and I sometimes had to focus fully on my studies. However, I spent many summers there and I don't regret it. We worked a lot, learned a lot and then had fun together on the evenings.`}</p>
    <h1>{`MyGuestic (2014-2017) - CTO`}</h1>
    <p>{`After I learned the foundamentals of softare development at Brainshots, I wanted to attempt going solo. My main motivation was probably money, but I also wanted to learn more about dealing with people and everything non-technical. I got my first client in 2014. The job was building something pretty similar to Airbnb but for the Chilean market. It sounded good to me so I broke it down to the individual software pieces, estimated the time and asked for an amount that at the time sounded like a lot to me.`}</p>
    <p><inlineCode parentName="p">{`Estimate * hourly-rate * buffer-multiplier`}</inlineCode>{`. It sounds good until it doesn't. I obviously failed to estimate correctly, but that was not the main thing I overlooked. The main issue was communication.`}</p>
    <p>{`When you explain an idea, even if it's very clear in your head, the other person won't picture exactly the same as you and many things will go unnoticed. For example, I assumed payments would be a simple PayPal integration. Charge the guest, pay the host, job done. However, we never talked about cancellation, or even cancellation policies (some hosts allowed full money-back, others refunded 50%, etc.). All these things were assumed to be included by my client, and I had failed to specify them.`}</p>
    <p>{`In the end, it took me at least four times more than what I had estimated. My buffer was less than 2x, so in summary, I got less than half my hourly rate at the time. Still, I don't regret it at all as I learned a lot. I learned to be more descriptive when describing features. I grasped how to better negotiate and instead of simply accepting the change in requirements, charge extra for it or offer an alternative.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      