import styled from "styled-components";
import colors from "../shared/colors";
import watercolor from "../images/watercolor.jpg";

const Title = styled.div`
  display: inline-block;
  text-align: center;
  font-family: "Advent Pro", sans-serif;
  text-transform: uppercase;
  flex-grow: 3;
  h1 {
    font-weight: 100;
    font-size: 4em;
    background: url(${watercolor}) repeat 5% 0%;
    background-size: auto;
    color: ${colors.white};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;

    animation-name: bgAnim;
    animation-duration: 80s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  h2 {
    color: ${colors.lightGray};
    font-weight: 100;
    font-size: 2.5em;
  }

  @keyframes bgAnim {
    from {
      background-position: 5% 0%;
    }
    to {
      background-position: 100% 100%;
    }
  }
`;

export default Title;
