import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Layout from "./layout";
import Section from "./section";
import Title from "./title";
import colors from "../shared/colors";
import styled from "styled-components";

const TitleH1 = styled.h1`
  font-size: 36px !important;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const H1 = styled.h1`
  margin: 40px 0 20px 0;
`;

const Link = styled.a`
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${colors.cyan};
  }
`;

const components = {
  h1: H1,
  a: Link,
};

const MdxLayout = ({ pageContext, children }) => {
  const {
    frontmatter: { title, path, date },
  } = pageContext;

  return (
    <Layout title={title}>
      <TitleContainer>
        <Title>
          <TitleH1>{title}</TitleH1>
        </Title>
      </TitleContainer>
      <Section textAlign="left" alignItems="flex-start">
        <MDXProvider components={components}>{children}</MDXProvider>
      </Section>
    </Layout>
  );
};

export default MdxLayout;

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          frontmatter {
            title
            path
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
